import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a href="#about-us" className="footer-link">About Us</a>
          <a href="#play-now" className="footer-link">Play Now</a>
          <a href="#contact" className="footer-link">Contact Us</a>
        </div>
        <div className="footer-text">
          <p>© {new Date().getFullYear()} <span className="highlight">MyCodeCafe</span>. All rights reserved.</p>
          <p>
            <a href="mailto:mohdtalib.dev@gmail.com" className="footer-email">
              mohdtalib.dev@gmail.com
            </a>
          </p>
        </div>
        <div className="footer-socials">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <ion-icon name="logo-github"></ion-icon>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
