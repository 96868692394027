import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Header.css'; // Import your custom CSS file

function Header() {
  return (
    <section className="hero-section" id="home" aria-label="home">
      <Container>
        <Row className="align-items-center justify-content-center">
          {/* Left Content Section */}
          <Col md={6} className="hero-content-col">
            <h1 className="section-title">
              Welcome to <span className="highlight">MyCodeCafe</span> – Where Coders Play, Learn, and Compete!
            </h1>
            <p className="hero-text">
              Swipe through fun coding quizzes, challenge your friends, and level up your skills in the ultimate gamified experience for developers.
            </p>
            <div className="hero-buttons">
              <a href="#play-now" className="btn btn-primary">
                Start Playing Now 🚀
              </a>
              <a href="#learn-more" className="btn btn-secondary">
                Learn More <ion-icon name="arrow-forward-outline"></ion-icon>
              </a>
            </div>
          </Col>

          {/* Right Image Section */}
          <Col md={6} className="hero-banner-col text-center">
            <img
              src={process.env.PUBLIC_URL + '/images/image_1.png'}
              alt="Hero"
              className="hero-image img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Header;
