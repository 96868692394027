import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.css';

function AboutUs() {
  return (
    <section className="about-us-section" id="about-us" aria-label="about-us">
      <Container>
        <Row className="align-items-center">
          {/* Image Section */}
          <Col md={6} className="about-us-image-col">
            <div className="about-us-image-wrapper">
              <img
                src={process.env.PUBLIC_URL + '/images/image_2.png'}
                alt="About Us"
                className="about-us-image img-fluid"
              />
            </div>
          </Col>

          {/* Content Section */}
          <Col md={6} className="about-us-content-col">
            <div className="about-us-content">
              <h2 className="about-us-title">
                Discover <span className="highlight">MyCodeCafe</span>
              </h2>
              <p className="about-us-text">
                A hub where developers and coders thrive! Whether you're learning something new or competing with friends, MyCodeCafe is the ultimate space to sharpen your skills and have fun.
              </p>
              <ul className="about-us-features">
                <li>🎮 Fun and engaging coding quizzes</li>
                <li>🤝 Friendly competition with peers</li>
                <li>🏆 Earn badges and rewards</li>
                <li>🌟 Perfect for learners and pros alike</li>
              </ul>
              <div className="about-us-buttons">
                <a href="#play-now" className="btn btn-primary">
                  Start Playing 🚀
                </a>
                <a href="#learn-more" className="btn btn-secondary">
                  Learn More <ion-icon name="arrow-forward-outline"></ion-icon>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutUs;
